p,
li,
a {
    font-size: 16px;
}

h1 {
    font-size: 30px;
    margin-bottom: 1rem;
}

h2 {
    font-size: 18px;
}

textarea {
    resize: none;
}

.App {
    text-align: center;
    overflow-x: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #f1f0f3;
    min-height: 91vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    font-size: calc(10px + 2vmin);
    color: rgb(0, 0, 0);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Custom Styles */

.allow-whitespace {
    white-space: pre-line;
}

.back-link {
    font-size: 16px;
}

.card {
    box-shadow: 2px 3px 15px #00000013;
    border-radius: 1rem;
    border: none;
}

.profile-picture {
    width: 225px;
    height: 225px;
    object-fit: cover;
}

.invis {
    position: absolute;
    border: none;
}

.form-text {
    font-size: 12px;
}

.form-group.required .form-label:after {
    content: ' *';
    color: red;
}

.form-row.required .form-label:after {
    content: ' *';
    color: red;
}

.list-group-item {
    border: none;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
}

.list-item-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.thick-divider {
    border-top: 2px solid black;
}

.or-divider {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgb(180, 180, 180);
    color: rgb(180, 180, 180);
    line-height: 0.1em;
    margin: 30px 0 30px;
}

.or-divider span {
    background: #fff;
    padding: 0 10px;
}

.dotted-divider {
    border-top: 1px dotted;
}

.bg-navbar {
    background-color: #0069d9 !important;
}

.small-text {
    font-size: 14px !important;
}

.font-arimo {
    font-family: 'Arimo', sans-serif;
}

.modal-width {
    max-width: 515px;
}

.mt-sm-3 {
    margin-top: 1rem;
}

.tooltip-inner {
    background-color: #e8f2fe;
    color: #718193;
    font-weight: 500;
    border-radius: 34px;
    font-size: 10px;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #e8f2fe;
}
