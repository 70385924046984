.landingpage {
    margin-top: -64px;
    padding: 0;
    width: 100%;
    background-color: white;
    font-family: 'Arimo', sans-serif;
}

.intro {
    box-sizing: border-box;
    /* clip-path: ellipse(168% 100% at -23.16% 0%); */
    text-align: center;
    padding: 1rem;
    height: max-content;
}

.desktop-pic {
    display: none;
}

.mobile-pic {
    display: inline;
}

.title {
    margin-left: 0;
}

.title-heading {
    font-size: 3em;
    line-height: 1;
    margin: 0;
    color: black;
}

b {
    font-weight: 900;
}

.title-content {
    font-size: 1rem;
    margin-top: 2rem;
    line-height: 1;
    margin-bottom: 2rem;
    color: grey;
    font-weight: 1;
}

.loginbutton {
    border-radius: 10px 10px 10px 10px;
    width: 15rem;
    height: 3rem;
    transition: background-color 0.25s, transform 0.25s;
    margin-bottom: 2rem;
    border-color: #fff !important;
}

.loginbutton:hover {
    background-color: #218838;
    border-color: #218838 !important;
    transform: translateY(-0.2rem);
    font-weight: bold;
}

.login-second-section {
    border-radius: 10px 10px 10px 10px;
    width: 15rem;
    height: 3rem;
    transition: background-color 0.25s, transform 0.25s;
    margin-bottom: 2rem;
    border-color: wheat !important;
}
.login-second-section:hover {
    background-color: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-0.2rem);
}

.landingpage {
    font-size: 1rem;
}

.summary {
    background-color: #007bff;
    color: aliceblue;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
}

.summary-title {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.summary h1 {
    font-weight: bold;
}

.summary p {
    font-size: 1rem;
    line-height: 2;
}

.landingIcon {
    height: 10vh;
    max-width: 10vh;
    margin-bottom: 20px;
}

.page {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.page h1 {
    font-weight: bold;
}

.page h2 {
    font-weight: bold;
}

.page-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

/* .card:hover {
    transform: scale(1.2);
    transition: all 1.2s;
} */

.flip-card {
    background-color: #218838;
    border-radius: 1em 1em 1em 1em;
    width: 100%;
    color: white;
}

.flip-card-front {
    text-align: center;
}

.finalsection {
    background-color: #007bff;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.finalsection h1 {
    font-weight: bold;
    color: white;
}

.finalsection-sub {
    padding-top: 2rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .landingpage {
        font-size: 1rem;
    }

    .intro {
        height: 91vh;
    }

    .website-pic {
        max-width: 100%;
    }

    .desktop-pic {
        display: inline;
    }

    .mobile-pic {
        display: none;
    }

    .title {
        position: relative;
        /* left: 5rem; */
    }

    .title-heading {
        font-size: 4vw;
    }

    .title-content {
        font-size: 1.2rem;
    }

    .summary {
        padding-left: 20rem;
        padding-right: 20rem;
    }

    /* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
    .flip-card {
        background-color: transparent;
        width: 40rem;
        height: 10rem;
        border: 1px solid #f1f1f1;
        perspective: 1000px; /* Remove this if you don't want the 3D effect */
        cursor: pointer;
    }

    /* This container is needed to position the front and back side */
    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    /* Do an horizontal flip when you move the mouse over the flip box container */
    .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
        border-radius: 1rem 1rem 1rem 5rem;
    }

    /* Style the front side (fallback if image is missing) */
    .flip-card-front {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #218838;
        color: white;
        font-weight: bold;
    }

    .flip-card-front h2 {
        font-size: 2rem;
    }

    /* Style the back side */
    .flip-card-back {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: dodgerblue;
        color: white;
        transform: rotateY(180deg);
        padding: 0.2rem;
    }
}

#leaves {
    position: relative;
    top: -50px;
    width: 100%;
    text-align: right;
}

#leaves i {
    display: inline-block;
    width: 200px;
    height: 150px;
    background: linear-gradient(to bottom right, #309900, #005600);
    transform: skew(20deg);
    border-radius: 5% 40% 70%;
    box-shadow: inset 0px 0px 1px #222;
    border: 1px solid #333;
    z-index: 1;
    -webkit-animation: falling 5s 0s infinite;
}

#leaves i:nth-of-type(2n) {
    -webkit-animation: falling2 5s 0s infinite;
}
#leaves i:nth-of-type(3n) {
    -webkit-animation: falling3 5s 0s infinite;
}

#leaves i:before {
    position: absolute;
    content: '';
    top: 117px;
    right: 9px;
    height: 27px;
    width: 32px;
    transform: rotate(49deg);
    border-radius: 0% 15% 15% 0%;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
    border-left: 0px solid #222;
    border-right: 1px solid #222;
    background: linear-gradient(to right, rgba(0, 100, 0, 1), #005600);
    z-index: 1;
}

#leaves i:after {
    content: '';
    height: 125px;
    width: 10px;
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.15),
        rgba(0, 0, 0, 0)
    );
    display: block;
    transform: rotate(125deg);
    position: absolute;
    left: 85px;
    border-radius: 50%;
}

#leaves i:nth-of-type(n) {
    height: 23px;
    width: 30px;
}
#leaves i:nth-of-type(n):before {
    width: 7px;
    height: 5px;
    top: 17px;
    right: 1px;
}
#leaves i:nth-of-type(n):after {
    width: 2px;
    height: 17px;
    left: 12px;
    top: 0px;
}

#leaves i:nth-of-type(2n + 1) {
    height: 11px;
    width: 16px;
}
#leaves i:nth-of-type(2n + 1):before {
    width: 4px;
    height: 3px;
    top: 7px;
    right: 0px;
}
#leaves i:nth-of-type(2n + 1):after {
    width: 2px;
    height: 6px;
    left: 5px;
    top: 1px;
}

#leaves i:nth-of-type(3n + 2) {
    height: 17px;
    width: 23px;
}
#leaves i:nth-of-type(3n + 2):before {
    height: 4px;
    width: 4px;
    top: 12px;
    right: 1px;
}
#leaves i:nth-of-type(3n + 2):after {
    height: 10px;
    width: 2px;
    top: 1px;
    left: 8px;
}

#leaves i:nth-of-type(n) {
    -webkit-animation-delay: 1.9s;
}
#leaves i:nth-of-type(2n) {
    -webkit-animation-delay: 3.9s;
}
#leaves i:nth-of-type(3n) {
    -webkit-animation-delay: 2.3s;
}
#leaves i:nth-of-type(4n) {
    -webkit-animation-delay: 4.4s;
}
#leaves i:nth-of-type(5n) {
    -webkit-animation-delay: 5s;
}
#leaves i:nth-of-type(6n) {
    -webkit-animation-delay: 3.5s;
}
#leaves i:nth-of-type(7n) {
    -webkit-animation-delay: 2.8s;
}
#leaves i:nth-of-type(8n) {
    -webkit-animation-delay: 1.5s;
}
#leaves i:nth-of-type(9n) {
    -webkit-animation-delay: 3.3s;
}
#leaves i:nth-of-type(10n) {
    -webkit-animation-delay: 2.5s;
}
#leaves i:nth-of-type(11n) {
    -webkit-animation-delay: 1.2s;
}
#leaves i:nth-of-type(12n) {
    -webkit-animation-delay: 4.1s;
}
#leaves i:nth-of-type(13n) {
    -webkit-animation-delay: 1s;
}
#leaves i:nth-of-type(14n) {
    -webkit-animation-delay: 4.7s;
}
#leaves i:nth-of-type(15n) {
    -webkit-animation-delay: 3s;
}

#leaves i:nth-of-type(n) {
    background: linear-gradient(to bottom right, #309900, #005600);
}
#leaves i:nth-of-type(2n + 2) {
    background: linear-gradient(to bottom right, #5e9900, #2b5600);
}
#leaves i:nth-of-type(4n + 1) {
    background: linear-gradient(to bottom right, #990, #564500);
}

#leaves i:nth-of-type(n) {
    opacity: 0.7;
}
#leaves i:nth-of-type(3n + 1) {
    opacity: 0.5;
}
#leaves i:nth-of-type(3n + 2) {
    opacity: 0.3;
}

#leaves i:nth-of-type(n) {
    transform: rotate(180deg);
}

#leaves i:nth-of-type(n) {
    -webkit-animation-timing-function: ease-in-out;
}

@-webkit-keyframes falling {
    0% {
        -webkit-transform: translate3d(300, 0, 0) rotate(0deg);
    }

    100% {
        -webkit-transform: translate3d(-350px, 700px, 0) rotate(90deg);
        opacity: 0;
    }
}

@-webkit-keyframes falling3 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) rotate(-20deg);
    }

    100% {
        -webkit-transform: translate3d(-230px, 640px, 0) rotate(-70deg);
        opacity: 0;
    }
}

@-webkit-keyframes falling2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
    }

    100% {
        -webkit-transform: translate3d(-400px, 680px, 0) rotate(0deg);
        opacity: 0;
    }
}
